/** @jsx jsx */
import { jsx } from '@emotion/core'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import s from './PopupContainer.style'

const PopupContainer = props => {
  const history = useHistory()
  const [show, setShow] = useState(props.show)

  useEffect(() => {
    if (props.show) {
      // !props.noHistory && history && history.push(history?.location?.pathname)

      window.onpopstate = e => {
        setShow(false)
        props.onClose()
      }

      setShow(props.show)
    }
    if (show && !props.show) onClose()
  }, [props.show]) // eslint-disable-line react-hooks/exhaustive-deps

  const onClose = () => {
    window.onpopstate = null

    // !props.noHistory && history && history.goBack()
    setShow(false)
    props.onClose()
  }

  const foregroundStyles = [s.foreground(props)]
  if (show) foregroundStyles.push(s.showForeground)
  return (
    <React.Fragment>
      {show ? <article css={s.bg} onClick={onClose} /> : null}
      <article css={foregroundStyles}>{props.children}</article>
    </React.Fragment>
  )
}

export default PopupContainer

/* Documentation Section */

PopupContainer.propTypes = {
  /**
   * Props description goes here
   */
  /**
   * on close
   */
  onClose: PropTypes.func,
  /**
   * on close
   */
  show: PropTypes.bool,
  /**
   * card Style
   */
  style: PropTypes.object,
  /**
   * Should popup handle browser history
   */
  noHistory: PropTypes.bool
}

PopupContainer.defaultProps = {
  onClose: () => console.log('On close')
}
