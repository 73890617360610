// TODO - Enter appropriate vapid key based on environment
// TODO - set auth domain based on environment
// TODO - set logout url
// Note: How to get vapid key

import { defaults } from 'react-sweet-state'
defaults.devtools = true

const FB_CONFIG_MAPPIN = {
  'swes-it': {
    // authDomain: 'pollish.io',
    vapidKey:
      'BI_AmWKO1dLGMnRv9CZzLLchBK0UBabc4Xuce0xMc4fUvENFp4At-unjWXiU6sKrfzdEid4irR0yKGzlB7wMTWg',
    execute: () => {
      console.log = () => {}
      window.__env = 'prod'
    }
  },
  'sponsear-stag': {
    vapidKey:
      'BJ1dCz2MuA0_32SltsN_Ois9opnPc7JePsfVyTODDmr1ZjJxJYKK6YhwVd-lzGsq2Sw7fFMZvTb-WP87yAGcuww',
    execute: () => {
      window.__env = 'staging'
    }
  }
}

// TODO - Enter firebase staging config here. This is to work without firebase emulator
const FB_STAGING_CONFIG = {
  apiKey: 'AIzaSyBs15CF-VQA4sIS0iLCFVJRfp2XRdjpO9k',
  authDomain: 'swes-it.firebaseapp.com',
  databaseURL: 'https://swes-it-default-rtdb.firebaseio.com',
  projectId: 'swes-it',
  storageBucket: 'swes-it.appspot.com',
  messagingSenderId: '237124523014',
  appId: '1:237124523014:web:787291a6a53d49b9e05f87',
  measurementId: 'G-9KX1G0XNYH'
}

// TODO - Set staging vapid key if no emulator
window.__vapidKey =
  'BJ1dCz2MuA0_32SltsN_Ois9opnPc7JePsfVyTODDmr1ZjJxJYKK6YhwVd-lzGsq2Sw7fFMZvTb-WP87yAGcuww'
// TODO - Set product Name
window.__productName = 'Wex 6 softwares'

export { FB_CONFIG_MAPPIN, FB_STAGING_CONFIG }
