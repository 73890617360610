import React, { useState, useEffect } from 'react'
import { useFullStore } from '../../Store/TemplatesStore'
import PopUpContainer from '../../Components/PopupContainer'
import { AiFillCheckCircle } from 'react-icons/ai'
import Button from '../../Components/Button'
import SmartTextBox from '../../Components/SmartTextBox'
import playIcon from '../LandingPage/play-circle.svg'
import TextArea from '../../Components/TextArea/TextArea'

const DemoLogin = () => {
  const [email, changeEmail] = useState('')
  const [password, changePassword] = useState('')
  const [state, actions] = useFullStore()

  const handleOnSubmit = () => {
    console.info('HOS')
    import('../../Lib')
      .then(({ loginWithEmail }) => loginWithEmail(email, password))
      .then(() => {
        window.location = '/u/'
      })
      .catch(() => alert('Invalid credentials'))
    // window.gtag_report_conversion && window.gtag_report_conversion(window.location.href)
  }

  return (
    <PopUpContainer
      show={state.showDemoLogin}
      onClose={() => {
        actions.showDemoLogin(false)
      }}
    >
      <div style={{ padding: 20 }}>
        <div>
          <p>Please enter the demo credentials you received over mail</p>
          <SmartTextBox
            onChange={changeEmail}
            placeholder={'Email'}
            value={email}
          />
          <SmartTextBox
            onChange={changePassword}
            placeholder={'Password'}
            type='password'
            value={password}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Button onClick={handleOnSubmit}>Login</Button>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer'
              }}
              onClick={() => {
                actions.showDemoLogin(false)
                actions.showEmailPopUp(true)
              }}
            >
              <span css={{ color: '#db3b61', fontSize: 13 }}>
                Get demo credentials
              </span>
            </div>
          </div>
        </div>
      </div>
    </PopUpContainer>
  )
}

export default DemoLogin
