import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useFullStore } from "../../Store/TemplatesStore";
import PopUpContainer from "../../Components/PopupContainer";
import { AiFillCheckCircle } from "react-icons/ai";
import Button from "../../Components/Button";
import SmartTextBox from "../../Components/SmartTextBox";
import playIcon from "../LandingPage/play-circle.svg";
import TextArea from "../../Components/TextArea/TextArea";

const GetEmail = () => {
  const [thankYou, showThankYou] = useState(false);
  const [email, changeEmail] = useState("");
  const [comment, changeComment] = useState("");
  const [state, actions] = useFullStore();
  const history = useHistory()

  const handleEmailChange = (val) => {
    changeEmail(val);
  };
  const handleCommentChange = (val) => {
    changeComment(val);
  };
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };
  const handleOnSubmit = () => {
    window.gtag_report_conversion && window.gtag_report_conversion(window.location.href)
    if (validateEmail(email)) {
      window.fbq('track', 'Lead');
      actions.addEmailLead(email, comment);
       history.push("/demo_success")
    } else {
      window.alert("Invalid Email Id");
    }
  };
  useEffect(() => {
    if (state.emailSubmitted) {
      showThankYou(true);
    }
  }, [state.emailSubmitted]);
  return (
    <PopUpContainer
      show={state.emailPopUp}
      onClose={() => {
        actions.showEmailPopUp(false);
      }}
    >
      <div style={{ padding: 20 }}>
        {thankYou ? (
          <article style={{ alignItems: "center" }}>
            <AiFillCheckCircle size={50} color="green" />
            <br />
            Thank you, We will get back to you within next 24 hours with
            detailed instructions for sign up
            <br />
            <br />
            <br />
            <Button
              onClick={() => {
                actions.showEmailPopUp(false);
              }}
            >
              CLOSE
            </Button>
          </article>
        ) : (
          <div>
            <p>Please enter your email to get demo account credentials</p>
            <SmartTextBox
              onChange={handleEmailChange}
              placeholder={"Email"}
              value={email}
            />
            <TextArea
              onChange={handleCommentChange}
              placeholder={"Comments"}
              value={comment}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button onClick={handleOnSubmit}>SUBMIT</Button>
              <div
                style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                onClick={() => {
                  window.open(
                    "https://www.youtube.com/watch?v=_0Bsj_nTtRc&feature=youtu.be",
                    "_blank" // <- This is what makes it open in a new window.
                  );
                }}
              >
                <img
                  src={playIcon}
                  style={{ width: "18px", height: "18px", marginRight: "8px" }}
                  alt="demo link"
                />
                <span css={{ fontSize: 13 }}>Watch a demo</span>
              </div>
            </div>
          </div>
        )}
      </div>
    </PopUpContainer>
  );
};

export default GetEmail;
