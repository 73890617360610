import React from 'react'
import PropTypes from 'prop-types'
import styles from './TextBox.module.scss'
import classnames from 'classnames'

const TextBox = ({
  type = 'text',
  value,
  onChange,
  placeholder,
  label,
  error,
  className,
  onFocus,
  onBlur,
  onKeyUp,
  mandatory,
  maxLength,
  disabled
}) => {
  return (
    <label className={classnames(styles.label, { className })}>
      {label}
      <input
        type={type}
        className={styles.textbox}
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        {...(onFocus ? { onFocus } : null)}
        {...(onBlur ? { onBlur } : null)}
        {...(onKeyUp ? { onKeyUp } : null)}
        mandatory={mandatory}
        maxLength={maxLength}
        readOnly={disabled}
      />
      {error ? (
        <span className={styles.error}>{error}</span>
      ) : (
        <span>&nbsp;</span>
      )}
    </label>
  )
}

export default TextBox

/* Documentation Section */

TextBox.propTypes = {
  /**
   * value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * onChange event
   */
  onChange: PropTypes.func,
  /**
   * type
   */
  type: PropTypes.oneOf(['text', 'number', 'email', 'search']),
  /**
   * placeholder
   */
  placeholder: PropTypes.string,
  /**
   * label
   */
  label: PropTypes.string,
  /**
   * error
   */
  error: PropTypes.string,
  /**
   * mandatory
   */
  mandatory: PropTypes.bool,
  /**
   * on Focus event
   */
  onFocus: PropTypes.func,
  /**
   * on onBlur event
   */
  onBlur: PropTypes.func,
  /**
   * on onKeyUp event
   */
  onKeyUp: PropTypes.func,
  /**
   * Max Length
   */
  maxLength: PropTypes.number,
  /**
   * Disabled
   */
  disabled: PropTypes.bool,
  /**
   * Track Event Name
   */
  trackEvent: PropTypes.string,
  /**
   * Track Event Props
   */
  trackEventProps: PropTypes.object
}

TextBox.defaultProps = {
  onChange: value => console.log('value', value),
  value: ''
}
