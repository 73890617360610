import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import TextBox from '../TextBox'

let eventDebounceTimer = null
export const eventDebounce = (func, delay = 600) => {
  clearTimeout(eventDebounceTimer)
  eventDebounceTimer = setTimeout(() => {
    if (func) func()
  }, delay)
}

const SmartTextBox = ({
  value,
  onChange,
  error,
  debounce,
  validator,
  ...props
}) => {
  const [_value, onTextChange] = useState(value)
  const [_error, onErrorChange] = useState(error)
  useEffect(() => {
    if (value !== _value) {
      updateText(value)
    }
    if (error !== _error) {
      onErrorChange(_error)
    }
  }, [value, error]) // eslint-disable-line react-hooks/exhaustive-deps

  const onTextBlur = () => {
    props.onBlur && props.onBlur(_value)
  }
  const updateText = val => {
    onTextChange(val)
    if (validator) {
      const resp = validator(val)
      if (resp.error) {
        onErrorChange(resp.error)
      }
    }
  }
  const _onTextChange = val => {
    onErrorChange('')
    updateText(val)
    if (debounce) {
      eventDebounce(() => {
        onChange(val)
      }, 600)
    } else {
      onChange(val)
    }
  }
  return (
    <TextBox
      {...props}
      value={_value}
      error={_error}
      onChange={_onTextChange}
      onBlur={onTextBlur}
    />
  )
}

export default SmartTextBox

/* Documentation Section */

SmartTextBox.propTypes = {
  /**
   * Value
   */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * On Change.
   */
  onChange: PropTypes.func,
  /**
   * Debounce (600ms)
   */
  debounce: PropTypes.bool,
  /**
   * Validator function
   */
  validator: PropTypes.func,
  /**
   * Track Event Name
   */
  trackEvent: PropTypes.string,
  /**
   * Track Event Name
   */
  trackEventProps: PropTypes.object
}

SmartTextBox.defaultProps = {
  value: '',
  onChange: val => {}
}
