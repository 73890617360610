import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import styles from './Button.module.scss'
import { BiLoaderAlt } from 'react-icons/bi'

const alignTypes = ['left', 'center', 'right']

const Button = ({
  children,
  primary,
  secondary,
  tertiary,
  className,
  loader,
  onClick,
  size,
  borderOnly,
  small,
  noShadow,
  wide,
  disabled,
  trackEvent,
  trackEventProps = {},
  align
}) => {
  const buttonClass = classnames(
    styles.button,
    {
      [styles.primary]: primary,
      [styles.secondary]: secondary,
      [styles.tertiary]: tertiary,
      [styles.borderOnly]: borderOnly,
      [styles.wide]: wide,
      [styles.small]: small,
      [styles.noShadow]: noShadow,
      [styles.disabled]: disabled
    },
    className
  )
  const Spinner = () => (
    <div className={styles.spinner}>
      <div className={styles.child}>{children}</div>
      <BiLoaderAlt />
    </div>
  )
  const onButtonClick = (e) => {
    if (!loader && !disabled) {
      onClick(e)
      if (trackEvent && window._trackEvent) {
        window._trackEvent(trackEvent, trackEventProps)
      }
    }
  }
  const alignMap = {
    [alignTypes[0]]: 'flex-start',
    [alignTypes[1]]: 'center',
    [alignTypes[2]]: 'flex-end'
  }
  const ButtonComp = () => (
    <button className={buttonClass} onClick={onButtonClick}>
      {loader ? <Spinner /> : children}
    </button>
  )
  const wrapperNeeded = align === 'center' || align === 'right' || size
  size = size || 14
  const WrappedButton = () => (
    <div
      className={styles.container}
      style={{
        fontSize: size,
        justifyContent: alignMap[align || alignTypes[0]]
      }}
    >
      <ButtonComp />
    </div>
  )
  return wrapperNeeded ? <WrappedButton /> : <ButtonComp />
}

export default Button

/* Documentation Section */

Button.propTypes = {
  /**
   * primary button
   */
  primary: PropTypes.bool,
  /**
   * secondary button
   */
  secondary: PropTypes.bool,
  /**
   * tertiary button
   */
  tertiary: PropTypes.bool,
  /**
   * On click
   */
  onClick: PropTypes.func,
  /**
   * className
   */
  className: PropTypes.string,
  /**
   * button text
   */
  children: PropTypes.string.isRequired,
  /**
   * show loader
   */
  loader: PropTypes.bool,
  /**
   * Size of the button, depends on the fontSize.
   */
  size: PropTypes.number,
  /**
   * Full width button
   */
  wide: PropTypes.bool,
  /**
   * Disable Button
   */
  disabled: PropTypes.bool,
  /**
   * Align item
   */
  align: PropTypes.oneOf(alignTypes),
  /**
   * Track Event Name
   */
  trackEvent: PropTypes.string,
  /**
   * Track Event Props
   */
  trackEventProps: PropTypes.object
}

Button.defaultProps = {
  onClick: () => console.log('Button clicked'),
  children: 'Button Text'
}
