import { createStore, createHook } from 'react-sweet-state'
import firebase from 'firebase/app'
import 'firebase/database'

const basePath = 'templates'

const Store = createStore({
  initialState: {},
  actions: {
    fetch: id => ({ setState, getState }) => {
      if (!id) return
      const idDetails = getState()[id]
      if (idDetails) return
      firebase
        .database()
        .ref(`${basePath}/${id}`)
        .on('value', d => {
          setState({
            [id]: d.val(),
            [`${id}_loaded`]: true
          })
        })
    },
    addEntry: params => async () => {
      return await firebase.functions().httpsCallable('addTemplate')(params)
    },
    updateEntry: (templateId, params) => async () => {
      return await firebase.functions().httpsCallable('addTemplate')({
        ...params,
        templateId
      })
    },
    deleteEntry: templateId => async () => {
      console.log('deleteEntry: ', templateId)
      return await firebase.functions().httpsCallable('deleteTemplate')({
        templateId
      })
    },
    addEmailLead: (email, comments) => ({ setState }) => {
      console.log('addEmailLead', email)
      firebase
        .database()
        .ref('leads')
        .push({
          email,
          comments,
          createAt: Date.now()
        })
      setState({
        emailSubmitted: true
      })
    },
    showEmailPopUp: (show = true) => ({ setState }) => {
      setState({
        emailPopUp: show
      })
    },
    showDemoLogin: (show = true) => ({ setState }) => {
      setState({
        showDemoLogin: show
      })
    }
  },
  name: basePath
})

const getDetails = (state, props) => {
  if (!props) return null
  if (!props.id) return state
  const key = `${props.id}_loaded`
  if (!state[key]) return { loading: true }
  const ret = state[props.id] || {}
  return ret
}

export const useFullStore = createHook(Store)

export const useStore = createHook(Store, {
  selector: getDetails
})
