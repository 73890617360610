const __bp = '@media (max-width: 640px)'
const styles = {
   container: {
     display: 'flex',
     position: 'fixed',
     top: 0,
     bottom: 0,
     left: 0,
     right: 0,
     background: '#3338',
     zIndex: 100
   },
   bg: {
     display: 'flex',
     position: 'fixed',
     top: 0,
     bottom: 0,
     left: 0,
     right: 0,
     background: '#333A',
     zIndex: 100
   },
   foreground: (props) => ({
     display: 'flex',
     boxShadow: '0px 0px 8px 3px #3338',
     position: 'fixed',
     background: 'white',
     transform: 'translateY(-120%)',
     left: 0,
     right: 0,
     top: 0,
     overflow:'inherit',
     maxWidth: '500px',
     width: '50vw',
     margin: 'auto',
     zIndex: 102,
     transition: 'all 0.3s ease-in-out',
     ...props.style,
     [__bp]: {
       maxWidth: 'unset',
       transform: 'translateY(100%)',
       bottom: -12,
       width: 'unset',
       top: 'unset'
     },
   }),
   showForeground: {
     top:'20vh',
     transform: 'translateY(0)',
     [__bp]: {
       top:'unset',
       bottom: 0,
       transform: 'translateY(0)'
     }
   }
 }

export default styles