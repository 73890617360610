/** @jsx jsx */
import { jsx } from '@emotion/core'
import PropTypes from 'prop-types'
import s from './PageLoader.style'
import LoaderItem from '../../Components/LoaderItem'

const PageLoader = props => {
  return (
    <article css={s.container}>
      <LoaderItem />
      <span css={{ width: '80%', textAlign: 'center' }}>{props.text}</span>
    </article>
  )
}
export default PageLoader

/* Documentation Section */

PageLoader.propTypes = {
  /**
   * Props description goes here
   */
  propName: PropTypes.string
}

PageLoader.defaultProps = {}
