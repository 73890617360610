import React, { Suspense, useState, useEffect } from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import FB from './Lib/FirebaseInit'
import PageLoader from './Components/PageLoader'
import GetEmail from './Containers/GetEmail'
import DemoLogin from './Containers/DemoLogin'

const LandingPage = React.lazy(() => import('./Containers/LandingPage/'))
const LoginView = React.lazy(() => import('./Containers/LoginView/'))
const PrivateRoute = React.lazy(() => import('./Containers/PrivateRoute/'))
const FirebaseRoute = React.lazy(() => import('./Containers/FirebaseRoute/'))
const MainView = React.lazy(() => import('./Containers/MainView/'))
const PricingView = React.lazy(() => import('./Containers/PricingView/'))
const PaymentPage = React.lazy(() => import('./Containers/PaymentPage/'))
const HelpView = React.lazy(() => import('./Containers/HelpView/'))
const PrivacyView = React.lazy(() => import('./Containers/PrivacyView/'))
const TermsView = React.lazy(() => import('./Containers/TermsView/'))

function App () {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    FB.initialize(() => {
      setLoaded(true)
    })
  }, [])
  if (!loaded) return <PageLoader />
  return (
    <Router>
      <GetEmail />
      <DemoLogin />
      <Suspense fallback={<PageLoader />}>
        <Switch>
          {/* <Route path='/login' component={LoginView} />
          <Route path='/pricing' component={PricingView} />
          <Route path='/help' component={HelpView} />
          <PrivateRoute path='/payment/:planId' component={PaymentPage} />
          <PrivateRoute path='/u/*' component={MainView} />
          <FirebaseRoute path='/demo_success' component={LandingPage} exact /> */}
          <Route path='/privacy' component={PrivacyView} />
          <Route path='/terms' component={TermsView} />
          <FirebaseRoute path='/' component={LandingPage} exact />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default App
