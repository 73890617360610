// Firebase App (the core Firebase SDK) is always required and must be listed first
import * as firebase from 'firebase/app'

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'
import { FB_CONFIG_MAPPIN, FB_STAGING_CONFIG } from '../Config/'


const initialize = callback => {
  fetch('/__/firebase/init.json')
    .then(r => r.json())
    .then(response => {
      const projectId = response.projectId
      const executor = FB_CONFIG_MAPPIN[projectId]?.execute || (() => {})
      executor()
      response.authDomain =
        FB_CONFIG_MAPPIN?.[projectId]?.authDomain || response.authDomain
      window.logoutUrl = FB_CONFIG_MAPPIN?.[projectId]?.logoutUrl
      window.__vapidKey = FB_CONFIG_MAPPIN?.[projectId]?.vapidKey
        
      firebase.initializeApp(response)
      callback()
    })
    .catch(() => {
      firebase.initializeApp(FB_STAGING_CONFIG)
      console.log('Fallback in init')
      callback()
    })
}

window._trackEvent = (event, eventProps, moengage) => {
  console.log('Event:', event)
  // window.Moengage.track_event(event, eventProps)
  firebase.analytics().logEvent(event, eventProps)
}

window.__trackEvent = (event, eventProps, moengage) => () => {
  console.log('EVV:', event)
  window._trackEvent(event, eventProps, moengage)
}

export default {
  initialize
}
